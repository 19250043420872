import React from 'react'
import './404.scss'

import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="missing-page subpage">
      <h1>Sidan hittas inte</h1>
      <p>
        Du hittade precis en sida som inte finns{' '}
        <span role="img" aria-label="">
          😧
        </span>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
